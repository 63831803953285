import React from 'react'

interface Props {
    active?: boolean
}

const Logo: React.FC<Props> = ({ active }) => (
    <svg
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1043.47 223.85"
        className={`navbar-logo ${active ? 'navbar-logo-active' : ''}`}
    >
        <path
            className="cls-2"
            d="M56.95,163.25l-18.5-99v-1h17l15.83,85h3.33l17.83-85h29l18,85h3.33l15.67-85h16v1l-18.5,99h-29.17l-18-85.67h-4.67l-18,85.67h-29.17Z"
        />
        <path
            className="cls-2"
            d="M224.29,165.75c-8.56,0-15.83-2.17-21.83-6.5s-10.58-10.42-13.75-18.25-4.75-17.08-4.75-27.75,1.58-19.92,4.75-27.75,7.78-13.92,13.83-18.25c6.05-4.33,13.42-6.5,22.08-6.5,7.55,0,13.58,1.58,18.08,4.75s7.64,6.75,9.42,10.75h4v-13h16v100h-16v-13h-4c-1.78,4-4.95,7.58-9.5,10.75-4.56,3.17-10.67,4.75-18.33,4.75Zm3.17-15c8.33,0,14.94-3.44,19.83-10.33,4.89-6.89,7.33-15.94,7.33-27.17s-2.45-20.28-7.33-27.17c-4.89-6.89-11.5-10.33-19.83-10.33s-14.33,3.28-18.67,9.83c-4.33,6.56-6.5,15.78-6.5,27.67s2.17,21.11,6.5,27.67c4.33,6.56,10.55,9.83,18.67,9.83Z"
        />
        <path
            className="cls-2"
            d="M328.45,165.75c-9.22,0-16.83-1.64-22.83-4.92-6-3.28-10.5-7.55-13.5-12.83-3-5.28-4.67-10.86-5-16.75h17.67c.33,3.33,1.39,6.58,3.17,9.75,1.78,3.17,4.39,5.75,7.83,7.75,3.44,2,7.83,3,13.17,3,5.89,0,10.44-1.25,13.67-3.75,3.22-2.5,4.83-5.8,4.83-9.92,0-3.55-1.25-6.58-3.75-9.08s-6.36-4.69-11.58-6.58l-11.83-4.17c-5.33-2-10.14-4.19-14.42-6.58-4.28-2.39-7.7-5.39-10.25-9-2.56-3.61-3.83-8.19-3.83-13.75,0-8.55,3.05-15.39,9.17-20.5,6.11-5.11,14.5-7.67,25.17-7.67,7.67,0,14.03,1.33,19.08,4,5.05,2.67,8.92,6.31,11.58,10.92,2.67,4.61,4.22,9.81,4.67,15.58h-17c-.45-4.22-2.11-8.03-5-11.42-2.89-3.39-7.61-5.08-14.17-5.08-5.22,0-9.22,1.14-12,3.42-2.78,2.28-4.17,5.36-4.17,9.25s1.33,6.89,4,9.33c2.67,2.45,6.67,4.67,12,6.67l11.83,4c5.33,2,10.11,4.2,14.33,6.58,4.22,2.39,7.53,5.36,9.92,8.92,2.39,3.56,3.58,8.11,3.58,13.67,0,8.56-3.11,15.56-9.33,21-6.22,5.45-15.22,8.17-27,8.17Z"
        />
        <path
            className="cls-2"
            d="M431.95,163.25l-18.5-99v-1h17l15.83,85h3.33l17.83-85h29l18,85h3.33l15.67-85h16v1l-18.5,99h-29.17l-18-85.67h-4.67l-18,85.67h-29.17Z"
        />
        <path
            className="cls-2"
            d="M599.28,165.75c-8.56,0-15.83-2.17-21.83-6.5s-10.58-10.42-13.75-18.25c-3.17-7.83-4.75-17.08-4.75-27.75s1.58-19.92,4.75-27.75c3.17-7.83,7.78-13.92,13.83-18.25,6.05-4.33,13.42-6.5,22.08-6.5,7.55,0,13.58,1.58,18.08,4.75s7.64,6.75,9.42,10.75h4v-13h16v100h-16v-13h-4c-1.78,4-4.95,7.58-9.5,10.75-4.56,3.17-10.67,4.75-18.33,4.75Zm3.17-15c8.33,0,14.94-3.44,19.83-10.33,4.89-6.89,7.33-15.94,7.33-27.17s-2.45-20.28-7.33-27.17c-4.89-6.89-11.5-10.33-19.83-10.33s-14.33,3.28-18.67,9.83c-4.33,6.56-6.5,15.78-6.5,27.67s2.17,21.11,6.5,27.67c4.33,6.56,10.55,9.83,18.67,9.83Z"
        />
        <path
            className="cls-1"
            d="M695.45,193.25c-4.33-6.56-8.42-14-12.25-22.33-3.83-8.33-6.92-17.36-9.25-27.08-2.33-9.72-3.5-19.92-3.5-30.58s1.17-20.86,3.5-30.58c2.33-9.72,5.42-18.75,9.25-27.08,3.83-8.33,7.92-15.78,12.25-22.33h17v1c-4.45,6.89-8.58,14.56-12.42,23-3.83,8.45-6.89,17.39-9.17,26.83-2.28,9.45-3.42,19.17-3.42,29.17s1.14,19.72,3.42,29.17c2.28,9.45,5.33,18.39,9.17,26.83,3.83,8.44,7.97,16.11,12.42,23v1h-17Z"
        />
        <path
            className="cls-2"
            d="M727.78,163.25V29.92h18v46.33h4c2.44-5,5.78-8.83,10-11.5s9.55-4,16-4c11.44,0,19.8,3.58,25.08,10.75,5.28,7.17,7.92,16.36,7.92,27.58v64.17h-18v-60.33c0-8.67-1.56-15.3-4.67-19.92-3.11-4.61-7.95-6.92-14.5-6.92-8.11,0-14.45,3.2-19,9.58-4.56,6.39-6.83,14.31-6.83,23.75v53.83h-18Z"
        />
        <path
            className="cls-1"
            d="M822.78,193.25v-1c4.55-6.89,8.72-14.56,12.5-23s6.8-17.39,9.08-26.83c2.28-9.44,3.42-19.17,3.42-29.17s-1.14-19.72-3.42-29.17c-2.28-9.44-5.31-18.39-9.08-26.83-3.78-8.44-7.95-16.11-12.5-23v-1h17c4.44,6.56,8.55,14,12.33,22.33,3.78,8.33,6.83,17.36,9.17,27.08,2.33,9.72,3.5,19.92,3.5,30.58s-1.17,20.86-3.5,30.58c-2.33,9.72-5.39,18.75-9.17,27.08-3.78,8.33-7.89,15.78-12.33,22.33h-17Z"
        />
        <path
            className="cls-2"
            d="M888.44,163.25V63.25h16v17h4c.67-2.78,1.83-5.55,3.5-8.33,1.67-2.78,4.33-5.08,8-6.92,3.67-1.83,8.83-2.75,15.5-2.75h3.33v17h-5c-10,0-17.06,2.58-21.17,7.75-4.11,5.17-6.17,13.25-6.17,24.25v52h-18Z"
        />
    </svg>
)

export default Logo

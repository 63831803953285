import { Link } from 'gatsby'
import React from 'react'
import CookieConsent from 'react-cookie-consent'

import Navbar from './components/Navbar'
import './styles/main.sass'

interface Props {
    transparentNavbar?: boolean
    currentPage?: string
}

const Layout: React.FC<Props> = ({ transparentNavbar, currentPage, children }) => (
    <div>
        <Navbar transparent={transparentNavbar} currentPage={currentPage} />
        {children}
        <div className="cookie-wrapper">
            <CookieConsent
                buttonText="Zustimmen"
                declineButtonText="Ablehnen"
                cookieName="gatsby-gdpr-google-analytics"
                enableDeclineButton
                disableStyles
                flipButtons
            >
                <p>
                    Wir nutzen Cookies, um Ihnen ein optimales Webseiten-Erlebnis zu bieten. Weitere
                    Informationen dazu finden Sie in unserer{' '}
                    <Link to="/impressum">Datenschutzerklärung</Link>.
                </p>
            </CookieConsent>
        </div>
    </div>
)

export default Layout

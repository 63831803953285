import React, { useState } from 'react'
import { Link } from 'gatsby'
import Logo from '../components/Logo'

interface Props {
    transparent?: boolean
    currentPage?: string
}

const Navbar: React.FC<Props> = ({ transparent, currentPage }) => {
    const [showMenu, setShowMenu] = useState<boolean>(false)

    return (
        <nav className={`navbar ${transparent ? 'navbar-transparent' : ''}`}>
            <div className="navbar-wrapper">
                <Link to="/" className="navbar-logo" onClick={() => setShowMenu(false)}>
                    <Logo active={currentPage === 'home'} />
                </Link>

                <div
                    className={`menu-icon ${showMenu ? 'menu-icon-open' : ''}`}
                    onClick={() => setShowMenu(!showMenu)}
                >
                    <span />
                    <span />
                    <span />
                </div>

                <div className={`navbar-menu ${showMenu ? 'navbar-menu-visible' : ''}`}>
                    <ul className="navbar-links">
                        <li className={currentPage === 'interviews' ? 'navbar-link-active' : ''}>
                            <Link to="/interviews" onClick={() => setShowMenu(false)}>
                                <span>{'(('}</span>Interviews<span>{'))'}</span>
                            </Link>
                        </li>
                        <li className={currentPage === 'archivsuche' ? 'navbar-link-active' : ''}>
                            <Link to="/archivsuche" onClick={() => setShowMenu(false)}>
                                <span>{'{{'}</span>Archivsuche<span>{'}}'}</span>
                            </Link>
                        </li>
                        <li className={currentPage === 'about' ? 'navbar-link-active' : ''}>
                            <Link to="/about" onClick={() => setShowMenu(false)}>
                                <span>{'[['}</span>About<span>{']]'}</span>
                            </Link>
                        </li>
                    </ul>

                    <ul className="navbar-links navbar-footer">
                        <li>
                            <Link to="/impressum" onClick={() => setShowMenu(false)}>
                                Impressum
                            </Link>
                        </li>
                        <li>
                            <a
                                href="mailto:information@waswahr.de"
                                onClick={() => setShowMenu(false)}
                            >
                                info@waswahr.de
                            </a>
                        </li>
                        <li>
                            <p>© 2022 Munich</p>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
